import store from '../store/index';

const serverUrl = process.env.VUE_APP_BACKEND_URL;
const frontEndUrl = process.env.VUE_APP_FRONTEND_URL;

const forgotPassword = async email => {
  const url = serverUrl + '/account/forgot-password';
  const body = {
    email: email
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    'Network login error from ', url, error;
  }
};

const loginUser = async (email, password) => {
  // Attempt login
  const url = serverUrl + '/account/login';
  const body = {
    email: email,
    password: password
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    console.log('Network login error from ', url, error);
  }
};

const signUpUser = async (email, password) => {
  // Attempt login
  const url = serverUrl + '/account/signup';
  const body = {
    email: email,
    password: password
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    console.log('Network login error from ', url, error);
  }
};

const confirmEmail = async token => {
  // Confirm email
  const url = serverUrl + '/account/confirm-email';
  const body = {
    token: token
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    console.log('Network login error from ', url, error);
  }
};

const getUserDetails = async () => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  // API to get account details and populate the account details
  const url = serverUrl + '/account/get-details';
  const body = {
    email: userEmail
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    console.log('Unable to load account details from ', url, error);
  }
};

const updateUserDetails = async (name, mobile, country, allowNotifications) => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  // API to get account details and populate the account details
  const url = serverUrl + '/account/update-details';
  const body = {
    email: userEmail,
    name: name,
    mobile: mobile,
    country: country,
    allowNotifications: allowNotifications
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { error: true, message: responseData.message };
    }
  } catch (error) {
    console.log('Unable to update account details from ', url, error);
  }
};

const getDevices = async () => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/account/get-devices';
  const body = {
    email: userEmail
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Unable to load device details from ', url, error);
  }
};

const getPackages = async () => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/shop/get-packages';
  const body = {
    email: userEmail
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();
    'Get Packages Response: ', responseData;
    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Unable to load packages from ', url, error);
  }
};

const getConnectionStatus = async imei => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  // API to get status
  const url = serverUrl + '/mobile/connection-status';
  const body = {
    email: userEmail,
    imei: imei
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Unable to load connection details from ', url, error);
  }
};

const getDataBundles = async imei => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  // API to get status
  const url = serverUrl + '/mobile/data-packages';
  const body = {
    email: userEmail,
    imei: imei
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { bundles: responseData.bundles, error: false };
    } else {
      const errorPackage = { ...responseData, error: true };
      console.log('Error ', errorPackage.message);
      if (errorPackage.message == 'jwt expired') {
        store.dispatch('logout');
      }
      return errorPackage;
    }
  } catch (error) {
    console.log('Unable to load data packages from ', url, error);
  }
};

const addDevice = async (ssid, password, name) => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;
  // API to get account details and populate the account details
  const url = serverUrl + '/account/add-device';
  const body = {
    email: userEmail,
    ssid: ssid,
    password: password,
    name: name
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem adding device with ', url, error);
  }
};

const changeEmail = async newEmail => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/account/change-email';
  const body = {
    email: userEmail,
    newEmail: newEmail
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem updating email with ', url, error);
  }
};

const changePassword = async (newPassword, email, token) => {
  let userToken = store.getters.userToken;
  if (token) {
    userToken = token;
  }
  let userEmail = store.getters.userEmail;
  if (email) {
    userEmail = email;
  }

  const url = serverUrl + '/account/change-password';
  const body = {
    email: userEmail,
    newPassword: newPassword
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem updating password with ', url, error);
  }
};

const editDevice = async (ssid, name) => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/account/update-device';
  const body = {
    email: userEmail,
    ssid: ssid,
    name: name
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem editing device with ', url, error);
  }
};

const deleteDevice = async ssid => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/account/delete-device';
  const body = {
    email: userEmail,
    ssid: ssid
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem deleting device with ', url, error);
  }
};

const getStripeSession = async (item, packageId, imei) => {
  const userToken = store.getters.userToken;
  const userEmail = store.getters.userEmail;

  const url = serverUrl + '/shop/get-stripe-session';
  const body = {
    email: userEmail,
    item: {
      name: item.name,
      description: item.description,
      amountUSD: item.amountUSD,
      quantity: item.quantity
    },
    imei: imei,
    packageId: packageId,
    frontEndUrl: frontEndUrl // Make env variable
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + userToken
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    });
    const responseData = await response.json();

    if (response.ok) {
      return { ...responseData, error: false };
    } else {
      return { ...responseData, error: true };
    }
  } catch (error) {
    console.log('Problem getting stripe session with ', url, error);
  }
};

export {
  signUpUser,
  loginUser,
  getUserDetails,
  updateUserDetails,
  getDevices,
  getConnectionStatus,
  getDataBundles,
  addDevice,
  changeEmail,
  changePassword,
  editDevice,
  deleteDevice,
  getPackages,
  getStripeSession,
  confirmEmail,
  forgotPassword
};
