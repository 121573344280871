<template>
  <section>
    <base-card class="container">
      <h3>Login to UNNIfi</h3>
      <form @submit.prevent="login">
        <div class="form-control">
          <label for="email">Email/Username</label>
          <input id="email" name="email" type="text" v-model="enteredEmail" />
        </div>
        <div class="form-control">
          <label for="password">Password</label>
          <input
            id="password"
            name="password"
            type="password"
            v-model="enteredPassword"
          />
        </div>
        <base-button>Login </base-button><br />
        <router-link to="/sign-up">Sign up for new account</router-link>
        <router-link to="/forgot-password">Forgot password</router-link>
        <label><hr></label>
        <label style="font-size: small;">Contact us at ask@unnifidata.com if you would like us to permanently delete your account here and in the UNNIfi mobile app.</label>
      </form>
      <div class="error" v-if="errorMsg">
        <p>{{ errorMsg }}</p>
      </div>
    </base-card>
  </section>
</template>

<script>
import { loginUser } from '../../api/backend';

export default {
  data() {
    return {
      enteredEmail: '',
      enteredPassword: '',
      errorMsg: null
    };
  },

  methods: {
    async login() {
      // Call login API
      const loginResponse = await loginUser(
        this.enteredEmail,
        this.enteredPassword
      );

      // Set store with returned login data and route to Connection Status
      if (!loginResponse.error) {
        this.errorMsg = '';
        this.enteredEmail = '';
        this.enteredPassword = '';
        await this.$store.dispatch('login', {
          isLoggedIn: true,
          userEmail: loginResponse.email,
          userToken: loginResponse.token
        });
        // this.$router.push('connection-status');
      } else {
        this.errorMsg = loginResponse.message;
      }
    }
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push('/connection-status');
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 19rem;
  width: 95%;
  margin: 5rem auto;
  background: white;
  padding: 2rem 2rem;
  display: block;
  border: 1px solid green;
}

.form-control {
  margin: 0.5rem 0;
}

.error {
  color: red;
}
a {
  margin-top: 0.5rem;
  display: block;
  text-decoration: none;
  font-weight: bold;
  color: rgb(194, 209, 29);
}

a:hover {
  color: gray;
}

input[type='text'],
input[type='password'] {
  display: block;
  width: 15rem;
  margin-top: 0.5rem;
}
</style>
